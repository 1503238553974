import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { prism } from "@tsg/1st-grpc-web";

import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export const selectionColumn = (handleActionClick, t): ExtendedGridColDef[] => {
  return [
    {
      field: "display_name",
      headerName: t("competition_display_name"),
      width: 50,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "shortName",
      headerName: "Short Name",
      editable: false,
      width: 25,
      valueGetter: (params: GridValueGetterParams) => params.row.shortName
    },
    {
      field: "state",
      headerName: t("competition_state"),
      width: 50,
      editable: false,
      hide: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `market_leg_enum.${
            prism.v1.data.MarketLegEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      editable: false,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Activate"
          onClick={() => handleActionClick("edit", params)}
          showInMenu
        />
      ]
    }
  ];
};
export default selectionColumn;
