import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { prism } from "@tsg/1st-grpc-web";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Selections from "pages/data/MarketSelection";

import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}
export interface SelectionsDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  selectedData: any[];
}

function SelectionsDialog(props: SelectionsDialogProps) {
  const { onClose, open, selectedData } = props;

  const handleClose = () => {
    onClose("");
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Selections selectedData={selectedData} />
    </Dialog>
  );
}

export const legsColumn = (handleActionClick, t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("market_name"),
      width: 50,
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      hide: true
    },
    {
      field: "competitionName",
      headerName: t("competitionName"),
      width: 50,
      valueGetter: (params: GridValueGetterParams) => params.row.competitionName
    },
    {
      field: "displayName",
      headerName: t("market_display_name"),
      width: 50,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "legNumber",
      headerName: t("leg_number"),
      width: 25,
      valueGetter: (params: GridValueGetterParams) => params.row.legNumber
    },
    {
      field: "numberOfWinningPositions",
      headerName: t("number_of_winning_positions"),
      width: 25,
      valueGetter: (params: GridValueGetterParams) => params.row.legNumber
    },
    {
      field: "results",
      headerName: t("results"),
      width: 25,
      valueGetter: (params: GridValueGetterParams) => params.row.results
    },
    {
      field: "Selections",
      renderCell: cellValues => {
        const [open, setOpen] = React.useState(false);
        const handleClickOpen = (event, cellValues) => {
          setOpen(true);
        };

        const handleClose = (value: string) => {
          setOpen(false);
        };
        return (
          <div>
            <Button
              variant="outlined"
              onClick={event => {
                handleClickOpen(event, cellValues);
              }}
            >
              Selections
            </Button>
            <SelectionsDialog
              open={open}
              onClose={handleClose}
              selectedData={cellValues.row}
            />
          </div>
        );
      }
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      editable: false,
      getActions: params => [
        // <GridActionsCellItem
        //   icon={<EditIcon />}
        //   label="Edit"
        //   onClick={() => handleActionClick("edit", params)}
        //   showInMenu
        // />
      ]
    }
  ];
};
export default legsColumn;
