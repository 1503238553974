import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Divider, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import { prism } from "@tsg/1st-grpc-web";
import AlertEnums = prism.v1.core.AlertEnums;

import AlertMessage from "./alert-message";
import { useAlertListStyles } from "./alert-styles";
import {
  AlertBadge,
  AlertListContainer,
  AlertListContent,
  AlertListHeader,
  AlertListSorting,
  AlertListSortingLabel,
  AlertListSortingSelect,
  AlertListTab,
  AlertListTabs,
  AlertListTitle,
  AlertListWrapper
} from "./alert-list-components";

type SortingDirection = "asc" | "desc";
interface OwnProps {
  alerts: prism.v1.core.IAlert[];
  onChangeSortingDirection: (direction: SortingDirection) => void;
  selectedTab: AlertEnums.State;
  changeTab: (tab: AlertEnums.State) => void;
  alertsContainerRef: React.RefObject<HTMLUListElement>;
  numberOfUnacknowledgedAlerts: number | undefined;
  numberOfAcknowledgedAlerts: number | undefined;
  isLoading: boolean;
}

const ALERTS_LIST_TABS = [
  AlertEnums.State.STATE_UNACKNOWLEDGED,
  AlertEnums.State.STATE_ACKNOWLEDGED,
  AlertEnums.State.STATE_RESOLVED,
  AlertEnums.State.STATE_MUTED
];

const AlertList = ({
  alerts,
  onChangeSortingDirection,
  isLoading,
  selectedTab,
  changeTab,
  alertsContainerRef,
  numberOfUnacknowledgedAlerts,
  numberOfAcknowledgedAlerts
}: OwnProps) => {
  const { t } = useTranslation();
  const classes = useAlertListStyles();
  const { activeAlertId } = useSelector((state: any) => state.alerts);

  const handleChange = (_, newValue: AlertEnums.State) => {
    changeTab(newValue);
  };
  const handleChangeSortingDirection = ({ target: { value } }) => {
    onChangeSortingDirection(value);
  };
  const renderAlerts = (alerts: prism.v1.core.IAlert[]) => {
    return (
      <AlertListWrapper ref={alertsContainerRef}>
        {alerts &&
          alerts?.map((alert, idx) => (
            <ListItemButton
              key={idx}
              selected={alert.name === activeAlertId}
              disableGutters
              classes={{
                selected: classes.selectedBg,
                root: classes.listItemRoot
              }}
            >
              <AlertMessage
                subjectType={alert.subjectType}
                parent={alert.parent}
                date={Number(alert.auditInfo?.createTime?.seconds) * 1000}
                subject={alert.subject}
                description={alert.description?.value}
                name={alert.name}
              />
            </ListItemButton>
          ))}
      </AlertListWrapper>
    );
  };

  return (
    <AlertListContainer>
      <AlertListHeader>
        <AlertListTitle>{t("alerts-center.title")}</AlertListTitle>
        <AlertListSorting>
          <AlertListSortingLabel>
            {t("alerts-center.sortingLabel")}
          </AlertListSortingLabel>
          <AlertListSortingSelect
            variant="standard"
            onChange={handleChangeSortingDirection}
            defaultValue={"desc"}
          >
            <MenuItem value="desc">Newest First</MenuItem>
            <MenuItem value="asc">Oldest First</MenuItem>
          </AlertListSortingSelect>
        </AlertListSorting>
      </AlertListHeader>
      <AlertListTabs value={selectedTab} onChange={handleChange}>
        {ALERTS_LIST_TABS.map((tab, index) => (
          <AlertListTab
            icon={
              (tab === AlertEnums.State.STATE_UNACKNOWLEDGED ||
                tab === AlertEnums.State.STATE_ACKNOWLEDGED) && (
                <AlertBadge
                  showZero
                  badgeContent={
                    tab === AlertEnums.State.STATE_UNACKNOWLEDGED
                      ? numberOfUnacknowledgedAlerts
                      : numberOfAcknowledgedAlerts
                  }
                  mode={index === 0 ? "primary" : "secondary"}
                />
              )
            }
            iconPosition="end"
            key={index}
            value={tab}
            label={t(`alerts-center.tabs.${AlertEnums.State[tab]}`)}
          />
        ))}
      </AlertListTabs>
      <Divider variant="fullWidth" className={classes.divider} />
      <AlertListContent disableGutters>
        {renderAlerts(alerts)}
        {isLoading && (
          <Box textAlign={"center"}>
            <CircularProgress />
          </Box>
        )}
      </AlertListContent>
    </AlertListContainer>
  );
};

export default AlertList;
