import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridFilterItem,
  GridRenderCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { MarketCommands } from "common/utils/enum-utils";
import { prism } from "@tsg/1st-grpc-web";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { convertMoneyToDouble } from "common/helper";
import Legs from "pages/data/Legs";

const actions = [
  {
    icon: <EventIcon />,
    command: MarketCommands.APPROVE_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.OPEN_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.CANCEL_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.CLOSE_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.INCOMPLETE_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.MANUALLY_SUSPEND_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.OFFICIAL_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.REFUND_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.REFUND_REQUESTED_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.RESULT_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.UNAUTO_SUSPEND_MARKET
  },
  {
    icon: <EventIcon />,
    command: MarketCommands.UNOFFICIAL_MARKET
  },
  {
    icon: <EditIcon />,
    command: MarketCommands.UPDATE_MARKET
  }
];

function LegsDialog(props: LegsDialogProps) {
  const { onClose, open, selectedData } = props;
  const handleClose = () => {
    onClose("");
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Legs selectedData={selectedData} />
    </Dialog>
  );
}

export const Columns = (handleActionClick, t): GridColumns => {
  return [
    {
      field: "name",
      headerName: t("market_name"),
      width: 200,
      hide: true,
      editable: false
    },
    {
      field: "displayName",
      headerName: t("market_display_name"),
      width: 300,
      editable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.displayName.value,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "sourceName",
      headerName: t("market_source"),
      width: 300,
      editable: true
    },
    {
      field: "marketTypeName",
      headerName: t("market_type"),
      width: 300,
      editable: true
    },
    {
      field: "Legs",
      renderCell: cellValues => {
        const [open, setOpen] = React.useState(false);
        const handleClickOpen = (event, cellValues) => {
          setOpen(true);
          console.log(cellValues.row);
        };

        const handleClose = (value: string) => {
          setOpen(false);
        };
        return (
          <div>
            <Button
              variant="outlined"
              onClick={event => {
                handleClickOpen(event, cellValues);
              }}
            >
              Legs
            </Button>
            <LegsDialog
              open={open}
              onClose={handleClose}
              selectedData={cellValues.row}
            />
          </div>
        );
      }
    },
    {
      field: "foreignIds",
      headerName: t("wagering_sources_foreign_ids"),
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <List>
          {params.row.foreignIds.map(fid => (
            <>
              {fid.type}
              <List dense={true}>
                {fid.values.map(value => (
                  <ListItem key={value} disableGutters disablePadding>
                    <ListItemText primary={value} sx={{ pl: 1 }} />
                  </ListItem>
                ))}
              </List>
            </>
          ))}
        </List>
      ),
      getApplyQuickFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem) {
          return null;
        }
        return (params: GridCellParams): boolean => {
          return params.value.find(fid =>
            fid.values.find(id => id.includes(filterItem))
          );
        };
      }
    },
    {
      field: "currency",
      headerName: t("market_currency"),
      width: 100,
      editable: false,
      sortable: true,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => params.row.currency || "",
      renderCell: (params: GridRenderCellParams) => params.row.currency || ""
    },
    {
      field: "minBetAmount",
      headerName: t("market_min_bet_amount"),
      width: 100,
      editable: false,
      sortable: true,
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.minBetAmount).toFixed(2),
      renderCell: (params: GridRenderCellParams) =>
        convertMoneyToDouble(params.row.minBetAmount).toFixed(2)
    },
    {
      field: "maxBetAmount",
      headerName: t("market_max_bet_amount"),
      width: 100,
      editable: false,
      sortable: true,
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.maxBetAmount).toFixed(2),
      renderCell: (params: GridRenderCellParams) =>
        convertMoneyToDouble(params.row.maxBetAmount).toFixed(2)
    },
    {
      field: "state",
      headerName: t("market_state"),
      width: 100,
      editable: false,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `market_price_market_state_enum.${
            prism.v1.data.MarketEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      editable: false,
      getActions: params =>
        actions.map(action => {
          return (
            <GridActionsCellItem
              icon={action.icon}
              label={t(`market_actions.${action.command}`)}
              onClick={() => handleActionClick(action.command, params)}
              showInMenu
            />
          );
        })
    }
  ];
};
