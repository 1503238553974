import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { DATA_MARKET_KEY } from "common/QueryKeys";
interface Props {
  row?: prism.v1.data.IMarketSelection;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
  originalData?: prism.v1.data.IMarket;
}

function MarketSelectionUpsert({
  row,
  handleSuccess,
  handleError,
  originalData
}: Props) {
  const { t } = useTranslation();
  const { useActivateMarketSelection } = useDataApi();
  const queryClient = useQueryClient();

  const upsertCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: [DATA_MARKET_KEY]
        });
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };

  const { mutate: mutateActivate, isPending: activateIsLoading } =
    useActivateMarketSelection(
      upsertCallback("parimutuel_add_success_message")
    );

  return (
    <Box sx={{ padding: "16px 25px" }}>
      <Typography variant="h6">
        {row ? "Activate" : t("")} {t("market_selection")}
      </Typography>
      <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
        <Formik
          initialValues={{
            displayName: row?.displayName?.value || ""
          }}
          onSubmit={data => {
            if (row) {
              const activateSection = {
                activateSelections: {
                  displayName: { value: data.displayName },
                  leg_number: data.leg_number,
                  short_name: [data.short_name]
                },
                name: row.name,
                fieldMask: {
                  paths: ["displayName", "displayColor"]
                }
              };
              mutateActivate(activateSection);
            }
          }}
        >
          {formik => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="displayName"
                label={t("display_name")}
                variant="outlined"
                size="medium"
                fullWidth
                required
                name="displayName"
                onChange={formik.handleChange}
                defaultValue={row?.displayName?.value || ""}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
              />
              <TextField
                id="leg_number"
                label={t("leg_number")}
                variant="outlined"
                size="medium"
                fullWidth
                required
                name="leg_number"
                onChange={formik.handleChange}
                defaultValue={row?.id || ""}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
              />
              <TextField
                id="short_name"
                label={t("short_name")}
                variant="outlined"
                size="medium"
                fullWidth
                required
                name="short_name"
                onChange={formik.handleChange}
                defaultValue={row?.shortName || ""}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
              />
              <TextField
                id="competitors"
                label={t("competitors")}
                variant="outlined"
                size="medium"
                fullWidth
                required
                name="competitors"
                onChange={formik.handleChange}
                defaultValue={row?.competitors || ""}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
              />
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                size="large"
                type="submit"
                loading={activateIsLoading}
                loadingPosition="start"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {row ? t("update_button_text") : t("add_button_text")}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
export default MarketSelectionUpsert;
