import {
  UseMutationOptions,
  UseQueryResult,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  QueryKey,
  QueryFunction,
  QueryFunctionContext
} from "@tanstack/react-query";
import { FirstApiError } from "./errors";

import store from "store";
import { pushSnackbar } from "store/actions/snackbars";
import { ESnackType } from "store/reducers/snackbars";
import { v4 } from "uuid";
import { useEffect } from "react";

// augment the react-query type to supply the 4th type argument to the options object
declare module "@tanstack/react-query" {
  function useInfiniteQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryData = TQueryFnData
  >(
    queryKey: QueryKey,
    queryFn: QueryFunction<TQueryFnData>,
    options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>
  ): UseInfiniteQueryResult<TData, TError>;
}

type RawApiFn = (request: any) => any;
type RawApiClient = {
  [methodName: string]: RawApiFn;
};

interface GenericPagedApiPlaceHolder {
  pagingOptions: Record<string, unknown>;
}

// Generates a list of keys that match "list*" or "get*"
type IsQueryMethodKey<T, K extends keyof T = keyof T> = K extends string
  ? K extends `pull${string}` | `list${string}` | `get${string}`
    ? K
    : never
  : never;

// a list of all query functions
type QueryMethodKeys<T> = IsQueryMethodKey<T>;
// a list of all mutation function
type MutationMethodKeys<T> = Exclude<
  Extract<keyof T, string>,
  IsQueryMethodKey<T>
>;

type GetApiReturnTypeInterface<TMethod extends RawApiFn> = Partial<
  Omit<ThenArg<ReturnType<TMethod>>, "toJSON">
>;

export type ReactQueryHooks<
  TClient,
  TQueryMethodKeys extends Extract<
    keyof TClient,
    string
  > = QueryMethodKeys<TClient>,
  TMutationMethodKeys extends Extract<
    keyof TClient,
    string
  > = MutationMethodKeys<TClient>
> = {
  [TMethodName in TQueryMethodKeys as `use${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
    ? ReactQueryApiQueryHook<
        Parameters<TClient[TMethodName]>[0],
        GetApiReturnTypeInterface<TClient[TMethodName]>
      >
    : never;
} & {
  [TMethodName in TQueryMethodKeys as `useInfinite${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
    ? ReactQueryApiInfiniteQueryHook<
        TClient[TMethodName],
        Parameters<TClient[TMethodName]>[0],
        GetApiReturnTypeInterface<TClient[TMethodName]>,
        GetListMessageResourceType<
          TMethodName,
          GetApiReturnTypeInterface<TClient[TMethodName]>
        >
      >
    : never;
} & {
  [TMethodName in TMutationMethodKeys as `use${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
    ? ReactQueryApiMutationHook<
        Parameters<TClient[TMethodName]>[0],
        GetApiReturnTypeInterface<TClient[TMethodName]>
      >
    : never;
} & {
  [TMethodName in TMutationMethodKeys as `use${Capitalize<TMethodName>}Query`]: TClient[TMethodName] extends RawApiFn
    ? ReactQueryApiQueryHook<
        Parameters<TClient[TMethodName]>[0],
        GetApiReturnTypeInterface<TClient[TMethodName]>
      >
    : never;
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
type ArrayItemType<T> = Exclude<
  T extends (infer U)[] ? U : T,
  undefined | null
>;

// Input: A method name like "listHorses"
// Output: The entity name as a type, e.g. "horses"
type GetEntityNameFromListMethodName<TMethodName, TListMessage> =
  TMethodName extends `list${infer TEntityName}`
    ? Lowercase<TEntityName> & keyof TListMessage
    : void;

type GetListMessageResourceType<
  TMethodName,
  TListMessage,
  TPropertyName extends keyof TListMessage = Exclude<
    GetEntityNameFromListMethodName<TMethodName, TListMessage>,
    void
  >
> = ArrayItemType<TListMessage[TPropertyName]>;

export interface ReactQueryApiInfiniteQueryHook<
  TMethodName,
  TRequest,
  TResult,
  TEntity
> {
  (
    request: TRequest,
    queryConfig?: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    >
  ): UseInfiniteQueryResult<
    { pages: TEntity[][]; pageParams: { totalResults?: number }[] },
    FirstApiError
  >;
  (
    key: string,
    request: TRequest,
    queryConfig?: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    >
  ): UseInfiniteQueryResult<
    {
      pages: TEntity[][];
      pageParams: { totalResults?: number }[];
    },
    FirstApiError
  >;
}

export interface CustomUseQueryOptions<TResult, FirstApiError>
  extends Omit<UseQueryOptions<TResult, FirstApiError>, "queryKey"> {
  onSuccess?: (data: TResult) => void;
  onError?: (error: FirstApiError) => void;
  // we don't require the queryKey and can dynamically set it,
  // so we want to redefine this to be optional
  queryKey?: string;
}

export interface ReactQueryApiQueryHook<TRequest, TResult> {
  (
    request: TRequest,
    queryConfig?: CustomUseQueryOptions<TResult, FirstApiError>
  ): UseQueryResult<TResult, FirstApiError>;
  (
    key: string,
    request: TRequest,
    queryConfig?: CustomUseQueryOptions<TResult, FirstApiError>
  ): UseQueryResult<TResult, FirstApiError>;
}

export type ReactQueryApiMutationHook<TRequest, TResult> = (
  config?: UseMutationOptions<TResult, FirstApiError, TRequest>
) => UseMutationResult<TResult, FirstApiError, TRequest>;

function capitalize(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

function useQuerySuccess(query, config) {
  useEffect(() => {
    if (config.enabled !== false && query.data && config.onSuccess) {
      config.onSuccess(query.data);
    }
  }, [query.data]);
}

function useQueryError(query, config) {
  useEffect(() => {
    if (config.enabled !== false && query.error && config.onError) {
      config.onError(query.error);
    }
  }, [query.error]);
}

function makeReactQueryQuery<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiQueryHook<TRequest, TResult> {
  return ((keyOrRequest: any, requestOrConfig: any, maybeConfig?: any) => {
    const onError = error => {
      console.error(error);
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: v4(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      if (maybeConfig?.onError) {
        maybeConfig.onError(error);
      } else {
        requestOrConfig?.onError && requestOrConfig.onError(error);
      }
    };

    const userConfig =
      typeof keyOrRequest === "string" ? maybeConfig : requestOrConfig;
    const queryKey =
      typeof keyOrRequest === "string" ? keyOrRequest : methodName;
    const request =
      typeof keyOrRequest === "string" ? requestOrConfig : keyOrRequest;
    const finalQueryConfig = {
      ...userConfig,
      // replace onError with our own
      onError
    };

    const query = useQuery<TResult, FirstApiError>({
      queryKey: [queryKey, request],
      queryFn: async ({ queryKey }) => apiClient[methodName](queryKey[1]),
      ...finalQueryConfig
    });

    // Custom hooks for handling success and error as react-query dropped support for these callbacks in v5
    useQuerySuccess(query, finalQueryConfig);
    useQueryError(query, finalQueryConfig);

    return query;
  }) as ReactQueryApiQueryHook<TRequest, TResult>;
}

function camelize(input: string) {
  return input
    .split("_")
    .map(s => s.charAt(0).toLowerCase() + s.slice(1))
    .join("");
}

function getEntityArrayFromMessage<TMessage, TEntity>(
  methodName: string,
  propertyName: string,
  message: TMessage
): TEntity[] {
  if (!(propertyName in message)) {
    throw new Error(
      `Unexpected API result result. The response to ${methodName} is expected to contain a property named ${propertyName} but none was found. Check spelling and pluralization.`
    );
  }

  return message[propertyName] as TEntity[];
}

function makeReactQueryInfiniteQuery<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>,
  TEntity = GetListMessageResourceType<TMethodName, TResult>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiInfiniteQueryHook<TMethodName, TRequest, TResult, TEntity> {
  type Options = UseInfiniteQueryOptions<
    TRequest,
    FirstApiError,
    TEntity,
    TResult
  >;
  // given "listHorses", get "horses"
  const propertyName = camelize(methodName.slice(4));

  return ((
    keyOrRequest: string | TRequest,
    requestOrOptions: TRequest | Options,
    maybeOptions?: Options
  ) => {
    const onError = error => {
      console.error(error);
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: v4(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      if (maybeOptions?.onError) {
        maybeOptions.onError(error);
      } else {
        "onError" in requestOrOptions && requestOrOptions.onError(error);
      }
    };

    const options =
      typeof keyOrRequest === "string" ? maybeOptions : requestOrOptions;
    const queryKey =
      typeof keyOrRequest === "string" ? keyOrRequest : `${methodName}Infinite`;
    const request =
      typeof keyOrRequest === "string" ? requestOrOptions : keyOrRequest;

    // merge the options with some default values and overrides.
    const mergedOptions: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    > = {
      getNextPageParam: (lastPage: any, allPages: any[]) =>
        lastPage.pagingInfo.nextPageToken?.length > 0
          ? lastPage.pagingInfo
          : undefined, // 'hasNextPage' property depends on getNextPageParam value: true on any value other then undefined
      select: result => {
        const getPageParams = () => {
          if (requestOrOptions?.pagingOptions?.includeSummary) {
            const pageParams = structuredClone(result.pageParams);
            pageParams[0] = {
              ...result.pageParams[0],
              totalResults: result.pages[0]?.pagingInfo?.totalResults
            };
            return pageParams;
          }
          return result.pageParams;
        };

        return {
          pages: result.pages.map(
            getEntityArrayFromMessage.bind(null, methodName, propertyName)
          ),
          pageParams: getPageParams()
        };
      },
      ...options,
      // replace onError with our own
      onError
    };

    // finally
    const query = useInfiniteQuery<TRequest, FirstApiError, TEntity, TResult>({
      queryKey: [queryKey, request],
      // call the api method, but automatically supply the next page token
      queryFn: async ({
        queryKey,
        pageParam
      }: QueryFunctionContext<[string, GenericPagedApiPlaceHolder]>) => {
        return apiClient[methodName]({
          ...queryKey[1],
          pagingOptions: {
            ...queryKey[1].pagingOptions,
            pageToken: pageParam.nextPageToken
          }
        });
      },
      initialPageParam: { nextPageToken: undefined },
      ...mergedOptions
    });

    // Custom hooks for handling success and error as react-query dropped support for these callbacks in v5
    useQuerySuccess(query, mergedOptions);
    useQueryError(query, mergedOptions);

    return query;
  }) as ReactQueryApiInfiniteQueryHook<TMethodName, TRequest, TResult, TEntity>;
}

function makeReactQueryMutation<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiMutationHook<TRequest, TResult> {
  return (config?: UseMutationOptions<TResult, FirstApiError, TRequest>) => {
    const onError = (error, variables, context) => {
      console.error(error);
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: v4(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      config?.onError && config.onError(error, variables, context);
    };
    return useMutation<TResult, FirstApiError, TRequest>({
      mutationFn: apiClient[methodName] as any,
      ...config,
      onError
    });
  };
}

export function makeHooksForApiClient<TClient>(
  apiClient: TClient
): ReactQueryHooks<TClient> {
  const hooks = {};

  for (const method of Object.keys(apiClient) as Extract<
    keyof TClient,
    string
  >[]) {
    if (/(list|get|pull)[A-Z].*/.test(method)) {
      hooks[`use${capitalize(method)}`] = makeReactQueryQuery(
        apiClient as any,
        method
      );
      hooks[`useInfinite${capitalize(method)}`] = makeReactQueryInfiniteQuery(
        apiClient as any,
        method
      );
    } else {
      hooks[`use${capitalize(method)}`] = makeReactQueryMutation(
        apiClient as any,
        method
      );
      hooks[`use${capitalize(method)}Query`] = makeReactQueryQuery(
        apiClient as any,
        method
      );
    }
  }

  return hooks as ReactQueryHooks<TClient>;
}
