import styled from "../../theme";
import {
  Box,
  List,
  Container,
  Typography,
  selectClasses,
  Select,
  Tabs,
  Tab,
  tabClasses,
  Badge,
  BadgeProps
} from "@mui/material";

export const AlertListWrapper = styled(List)(() => ({
  maxWidth: "800px",
  minWidth: "511px"
}));
export const AlertListContainer = styled(Container)(() => ({
  margin: "24px 0px"
}));
export const AlertListHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px"
}));
export const AlertListTitle = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "20px"
}));
export const AlertListSorting = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));
export const AlertListSortingLabel = styled(Typography)(() => ({
  fontSize: "16px",
  lineHeight: "20px",
  color: "#6361B8",
  marginRight: "8px"
}));
export const AlertListSortingSelect = styled(Select)(() => ({
  [`& .${selectClasses.icon}`]: {
    color: "#6361B8"
  }
}));
export const AlertListTabs = styled(Tabs)(() => ({
  marginBottom: "24px"
}));
export const AlertListTab = styled(Tab)(() => ({
  overflow: "visible !important",
  marginRight: "24px",
  color: "#6361B8",
  textTransform: "none",
  fontSize: "16px",
  lineHeight: "20px",
  minHeight: "44px",
  [`&.${tabClasses.selected}`]: {
    color: "#282535"
  }
}));

interface IBadge extends BadgeProps {
  mode: "primary" | "secondary";
}
export const AlertBadge = styled(Badge)<IBadge>(({ mode }) => {
  if (mode === "primary") {
    return {
      "& span": {
        color: "#FFF",
        backgroundColor: "#CE5D5D",
        top: 0,
        right: -8
      }
    };
  }
  if (mode === "secondary") {
    return {
      "& span": {
        color: "#6361B8",
        backgroundColor: "#FFF",
        border: "1px solid #6361B8",
        radius: "9px",
        top: 0,
        right: -8
      }
    };
  }
  return {};
});

export const AlertListContent = styled(Container)(() => ({
  maxHeight: "75vh",
  overflowY: "scroll",
  scrollbarWidth: "none",
  paddingTop: "24px"
}));
