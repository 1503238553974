import { createStyles, makeStyles } from "@mui/styles";

export const useAlertListStyles = makeStyles(() => {
  return createStyles({
    selectedBg: {
      backgroundColor: "#6361B81A !important",
      border: "1px solid #484686 !important",
      borderLeft: "10px solid #484686 !important",
      paddingLeft: "10px !important"
    },
    listItemRoot: {
      marginBottom: "12px",
      borderRadius: "8px",
      border: "1px solid #6361B880",
      padding: "8px 20px"
    },
    divider: {
      borderColor: "#6361B880",
      borderWidth: "1px",
      borderStyle: "solid",
      marginLeft: "-24px",
      marginRight: "-24px",
      marginTop: "-24px"
    }
  });
});
