import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { DATA_MARKET_KEY } from "common/QueryKeys";
import { GridActions, MarketCommands } from "common/utils/enum-utils";
import { usePrismWebContext } from "components/common/context";
import { AlertBox } from "components/common/Dialog";
import { GridComponent } from "components/common/Grid";
import MarketUpsert from "components/data/Market/upsert/upsert";
import MarketActionView from "components/data/Market/MarketActionView/MarketActionView";
import { useDataApi } from "hooks/api/data/DataAPI";
import { Columns } from "./column";
import { prism } from "@tsg/1st-grpc-web";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";

const marketCommandView = {
  [MarketCommands.UPDATE_MARKET]: <MarketUpsert />
};

export default function MarketPlusPage() {
  const { t } = useTranslation();
  const { organizationUnit, organization } = usePrismWebContext();
  const [organizationUnitName, setOrganizationUnitName] = organizationUnit;
  const [organizationName, setOrganizationName] = organization;
  const { useListMarkets } = useDataApi();
  const queryClient = useQueryClient();
  const [openAlert, setOpenAlert] = useState(false);
  const actionMethod = useRef(null);
  const [displayAllMarkets, setDisplayAllMarkets] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [erroMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [command, setCommand] = useState<MarketCommands | null>(null);
  const [filterState, setFilterState] = useState({ state: [] });

  const { data, isLoading, refetch, isRefetching } = useListMarkets(
    DATA_MARKET_KEY,
    {
      filter: [
        {
          ...filterState
        }
      ],
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      }
    }
  );

  useEffect(() => {
    // Set the filter state to an array of all market state enum values if displayAllMarkets is true
    // Otherwise, set the filter state to an empty array (default)
    setFilterState({
      state: displayAllMarkets
        ? Object.values(prism.v1.data.MarketEnums.State)?.filter(
            state => state !== 0
          )
        : []
    });
  }, [displayAllMarkets]);

  let marketData = [];
  if (data?.markets) {
    marketData = data.markets;
    console.log(marketData);
  }

  const handleActionClick = (command: MarketCommands, params) => {
    const actionNames: Record<MarketCommands, GridActions> | null = null;
    const actionName = actionNames?.[command] || GridActions.EDIT;
    setCommand(command);
    actionMethod.current(actionName, params.id);
  };
  const getActionView = () => {
    return marketCommandView[command] || <MarketActionView command={command} />;
  };
  return (
    <>
      <GridComponent
        rowData={marketData}
        activeStateFilter={x => setDisplayAllMarkets(x)}
        columnDefs={Columns(handleActionClick, t)}
        idProp={"name"}
        pageTitle={t("market")}
        checkboxLabel={t("display_all_markets")}
        tooltipTitle={t("create_market")}
        editType="modal"
        actionMethod={actionMethod}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading || isRefetching}
        modalSize={"lg"}
        disableAddButton={true}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
      >
        {getActionView()}
      </GridComponent>
      <AlertBox
        successMessage={successMessage}
        errorMessage={erroMessage}
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  );
}
