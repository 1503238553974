import React, { useRef, useState } from "react";

import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import { ALERTS_KEY } from "common/QueryKeys";
import { DEFAULT_ALERTS_DISPLAY_LIMIT } from "common/prism-constant";
import AlertNotifications from "./alert-notifications";
import AlertsCenter from "./alerts-center";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const AlertIconButton = () => {
  const listRef = useRef(null);
  const [openAlerts, setOpenAlerts] = useState<boolean>(false);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);

  const { useInfiniteListAlerts } = useAlertApi();

  const {
    data: alertsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteListAlerts(ALERTS_KEY, {
    pagingOptions: {
      maxResults: DEFAULT_ALERTS_DISPLAY_LIMIT,
      includeSummary: true
    },
    getOptions: {
      orderBy: [`auditInfo.createTime desc`]
    }
  });
  useInfiniteScroll(
    listRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );
  const handleNotifications = () => {
    setOpenNotifications(true);
  };

  const handleCloseAlerts = () => {
    setOpenAlerts(false);
    if (openNotifications) {
      setOpenNotifications(false);
    }
  };

  const badgeContent = alertsData?.pageParams[0]?.totalResults || 0;
  const recentAlerts = alertsData?.pages.flat();

  return (
    <>
      <IconButton onClick={handleNotifications} aria-hidden={true}>
        <Badge
          badgeContent={badgeContent}
          sx={{
            "& span": {
              color: "#FFF",
              backgroundColor: "#CD1111",
              border: "1px solid #FFF",
              top: 1,
              right: 1
            }
          }}
        >
          <NotificationsIcon
            sx={{
              color: "#484686"
            }}
          />
        </Badge>
      </IconButton>
      <AlertNotifications
        alertsContainerRef={listRef}
        open={openNotifications}
        openAlertCenter={() => setOpenAlerts(true)}
        onClose={() => setOpenNotifications(false)}
        alertsPreview={recentAlerts}
        isFetchAlerts={isFetchingNextPage}
      />

      <Dialog
        open={openAlerts}
        onClose={handleCloseAlerts}
        fullScreen
        TransitionComponent={Transition}
      >
        <AlertsCenter onClose={handleCloseAlerts} />
      </Dialog>
    </>
  );
};

export default AlertIconButton;
