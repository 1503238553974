/* eslint-disable complexity */
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { DATA_COMPETITION_KEY } from "common/QueryKeys";
import { buttonStyle } from "components/style/app-style";

interface Props {
  row?: prism.v1.data.ICompetitor;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
  originalData?: prism.v1.data.ICompetition;
}

function CompetitionCompetitorUpsert({
  row,
  handleSuccess,
  handleError,
  originalData
}: Props) {
  const { t } = useTranslation();
  const { useUpdateCompetitionCompetitor } = useDataApi();
  const queryClient = useQueryClient();

  const upsertCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [DATA_COMPETITION_KEY] });
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };
  const { mutate: mutateUpdate, isPending: updateIsLoading } =
    useUpdateCompetitionCompetitor(
      upsertCallback("competitor_update_success_message")
    );

  return (
    <Box sx={{ padding: "16px 25px" }}>
      <Typography variant="h6">
        {row ? t("update") : t("add")} {t("competitor")}
      </Typography>
      <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
        <Formik
          initialValues={{
            shortName: row?.shortName,
            displayName: row?.displayName.value,
            displayColor: row?.displayColor,
            bettingIntersest: row?.racingCompetitor?.bettingInterest
          }}
          onSubmit={data => {
            if (row) {
              mutateUpdate({
                competitor: {
                  displayName: { value: data.displayName },
                  displayColor: data.displayColor,
                  shortName: data.shortName
                },
                name: originalData.selectedData?.name,
                fieldMask: {
                  paths: ["displayName", "displayColor"]
                }
              });
            }
          }}
        >
          {formik => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container alignItems="center">
                <TextField
                  id="displayName"
                  label={t("competition_display_name")}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="displayName"
                  onChange={formik.handleChange}
                  defaultValue={row?.displayName.value}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                />
                <TextField
                  id="shortName"
                  label={"Short Name"}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="shortName"
                  onChange={formik.handleChange}
                  defaultValue={row?.shortName}
                  error={
                    formik.touched.shortName && Boolean(formik.errors.shortName)
                  }
                  disabled={true}
                />
                <TextField
                  id="displayColor"
                  label={"Display Color"}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="displayColor"
                  onChange={formik.handleChange}
                  defaultValue={row?.displayColor}
                  error={
                    formik.touched.displayColor &&
                    Boolean(formik.errors.displayColor)
                  }
                />
                <TextField
                  id="bettingInterest"
                  label={"Betting Interest"}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="bettingInterest"
                  onChange={formik.handleChange}
                  defaultValue={row?.racingCompetitor?.bettingInterest}
                  error={
                    formik.touched.displayColor &&
                    Boolean(formik.errors.displayColor)
                  }
                  disabled={true}
                />
                <TextField
                  id="startingPositon"
                  label={"Starting Position"}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="startingPositon"
                  onChange={formik.handleChange}
                  defaultValue={row?.racingCompetitor?.startingPositon}
                  error={
                    formik.touched.startingPositon &&
                    Boolean(formik.errors.startingPositon)
                  }
                  disabled={true}
                />
                <TextField
                  id="horseName"
                  label={"Horse Name"}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="horseName"
                  onChange={formik.handleChange}
                  defaultValue={row?.racingCompetitor?.horseName}
                  error={
                    formik.touched.horseName && Boolean(formik.errors.horseName)
                  }
                  disabled={true}
                />
              </Grid>
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                sx={row ? buttonStyle() : null}
                type="submit"
                size="large"
                loading={updateIsLoading}
                loadingPosition="start"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {row ? t("update_button_text") : t("add_button_text")}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default CompetitionCompetitorUpsert;
