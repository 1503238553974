import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { Allotment } from "allotment";
import "allotment/dist/style.css";

import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

import { ALERTS_KEY } from "common/QueryKeys";
import { DEFAULT_ALERTS_DISPLAY_LIMIT } from "common/prism-constant";

import { prism } from "@tsg/1st-grpc-web";

import AlertDetails from "./alert-details";
import AlertList from "./alert-list";
import AlertEnums = prism.v1.core.AlertEnums;

type SortingDirection = "asc" | "desc";
interface OwnProps {
  onClose: () => void;
}

const AlertsCenter = ({ onClose }: OwnProps) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState<AlertEnums.State>(
    AlertEnums.State.STATE_UNACKNOWLEDGED
  );
  const [activeAlert, setActiveAlert] = useState<prism.v1.core.IAlert>(null);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirection>("desc");
  const { activeAlertId } = useSelector((state: any) => state.alerts);
  const { useInfiniteListAlerts, useListAlerts } = useAlertApi();

  const {
    data: alertsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteListAlerts(ALERTS_KEY, {
    filter: [
      {
        state: [selectedTab]
      }
    ],
    pagingOptions: {
      maxResults: DEFAULT_ALERTS_DISPLAY_LIMIT,
      includeSummary: true
    },
    getOptions: {
      orderBy: [`auditInfo.createTime ${sortingDirection}`]
    }
  });
  const {
    data: numberOfUnacknowledgedAlertsResponse,
    refetch: refetchUnacknowledgedAlertsResponse
  } = useListAlerts(ALERTS_KEY + "_unacknowledged_alerts_number", {
    filter: [
      {
        state: [AlertEnums.State.STATE_UNACKNOWLEDGED]
      }
    ],
    pagingOptions: {
      maxResults: 1,
      includeSummary: true
    }
  });
  const {
    data: numberOfAcknowledgedAlertsResponse,
    refetch: refetchAcknowledgedAlertsResponse
  } = useListAlerts(ALERTS_KEY + "_acknowledged_alerts_number", {
    filter: [
      {
        state: [AlertEnums.State.STATE_ACKNOWLEDGED]
      }
    ],
    pagingOptions: {
      maxResults: 1,
      includeSummary: true
    }
  });
  useInfiniteScroll(
    listRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  useEffect(() => {
    if (activeAlertId) {
      const alert = alertsData?.pages
        ?.flat()
        ?.find(alert => alert.name === activeAlertId);
      setActiveAlert(alert);
    }
  }, [activeAlertId]);
  const handleChangeTab = (tab: AlertEnums.State) => {
    setSelectedTab(tab);
    refetchUnacknowledgedAlertsResponse();
    refetchAcknowledgedAlertsResponse();
  };

  const handleChangeSortingDirection = (direction: SortingDirection) => {
    setSortingDirection(direction);
  };

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "#282535",
          justifyContent: "center"
        }}
      >
        <Toolbar>
          <Button
            onClick={onClose}
            sx={{
              color: "#7ED8FF",
              fontSize: "20px",
              lineHeight: "22px",
              textTransform: "none",
              fontWeight: 400,
              flex: "1",
              marginRight: "auto",
              justifyContent: "flex-start",
              padding: 0
            }}
          >
            {t("alerts-center.closeButton")}
          </Button>
          <Typography
            align="center"
            sx={{
              color: "#FFFFFF",
              fontSize: "20px",
              fontWeight: 500,
              flex: "1"
            }}
          >
            {t("alerts-center.header")}
          </Typography>
          <Box
            sx={{
              flex: "1",
              marginLeft: "auto"
            }}
          />
        </Toolbar>
      </AppBar>
      <Allotment>
        <AlertList
          alerts={alertsData?.pages?.flat()}
          alertsContainerRef={listRef}
          onChangeSortingDirection={handleChangeSortingDirection}
          selectedTab={selectedTab}
          changeTab={handleChangeTab}
          numberOfUnacknowledgedAlerts={
            numberOfUnacknowledgedAlertsResponse?.pagingInfo?.totalResults
          }
          numberOfAcknowledgedAlerts={
            numberOfAcknowledgedAlertsResponse?.pagingInfo?.totalResults
          }
          isLoading={isFetchingNextPage}
        />
        <AlertDetails activeAlert={activeAlert} />
      </Allotment>
    </>
  );
};

export default AlertsCenter;
