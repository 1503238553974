import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridComponent } from "components/common/Grid";
import selectionColumn from "../MarketSelection/selectionColumn";
import {
  IMarket,
  IMarketSelection
} from "@tsg/1st-grpc-web/src/generated/stronachgroup/first/prism/v1/data";
import MarketSelectionUpsert from "components/data/MarketSelection/upsert/upsert";

export default function Selections(marketData: IMarketSelection) {
  const { t } = useTranslation();
  const actionMethod = useRef(null);
  const handleActionClick = (actionName, params) => {
    actionMethod.current(actionName, params.id);
  };
  let marketLegs = [];
  marketLegs = marketData?.selectedData?.selections;
  marketLegs = marketLegs.map((item, index) => ({ ...item, id: index + 1 }));
  marketLegs = marketLegs.map(item => ({
    ...item,
    name: marketData.selectedData?.name
  }));

  return (
    <>
      <GridComponent
        idProp={"id"}
        rowData={marketLegs}
        columnDefs={selectionColumn(handleActionClick, t)}
        actionMethod={actionMethod}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }]
          }
        }}
        pageTitle="Market Selections"
        rowHeight={"auto"}
        showAdd={false}
      >
        <MarketSelectionUpsert originalData={marketLegs} />
      </GridComponent>
    </>
  );
}
